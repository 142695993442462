<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="name"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`name.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model="form.name[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col
              cols="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="district"
                :name="$t('district')"
                rules="required"
              >
                <b-form-group
                  :label="$t('district')"
                >
                  <v-select
                    v-model="form.district"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name"
                    :options="districts"
                    :reduce="item => item.id"
                    :placeholder="$t('district')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="facility_type_id"
                :name="$t('facility_type')"
                rules="required"
              >
                <b-form-group
                  :label="$t('facility_type')"
                >
                  <v-select
                    v-model="form.facility_type_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name"
                    :options="facilities"
                    :reduce="item => item.id"
                    :placeholder="$t('facility_type')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('latitude_coordinates')"
                vid="lat"
                rules="required"
              >
                <b-form-group
                  :label="$t('latitude_coordinates')"
                >
                  <b-form-input
                    v-model="form.lat"
                    :placeholder="$t('latitude_coordinates')"
                    autocomplete="off"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('longitude_coordinates')"
                vid="lng"
                rules="required"
              >
                <b-form-group
                  :label="$t('longitude_coordinates')"
                >
                  <b-form-input
                    v-model="form.lng"
                    :placeholder="$t('longitude_coordinates')"
                    autocomplete="off"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12 mb-4"
            >
              <GmapMap
                :center="center"
                :zoom="8"
                style="height: 400px"
                @click="handleMapClick"
              >
                <GmapMarker :options="{ position: center }" />
              </GmapMap>
            </b-col>
            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="description"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`description.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-textarea
                        v-model="form.description[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                vid="icon"
                :name="$t('labels.icon')"
                rules="required"
              >
                <ImagePreview
                  v-model="icon"
                  :label="$t('labels.icon')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12 text-right mt-4">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { googleKey } from '@/services/url.service'

export default {
  mixins: [formMixin],
  data() {
    return {
      center: {
        lat: 24.774265,
        lng: 46.738586,
      },
      districts: [],
      facilities: [],
      icon: null,
      form: {
        district: null,
        name: {
          en: null,
          ar: null,
        },
        description: {
          en: null,
          ar: null,
        },
        facility_type_id: null,
        lat: null,
        lng: null,
        icon: null,
      },
    }
  },
  watch: {
    icon(val) {
      if (val) {
        this.form.icon = val
      } else {
        this.form.icon = 'delete'
      }
    },
  },
  created() {
    this.getDistricts()
    this.getFacilities()
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    getDistricts() {
      this.axios.get('/districts/list')
        .then(res => {
          const { data } = res.data
          this.districts = data
        })
    },
    getFacilities() {
      this.axios.get('/facilities-types/list')
        .then(res => {
          const { data } = res.data
          this.facilities = data
        })
    },
    async handleMapClick(event) {
      this.center.lat = event.latLng.lat()
      this.center.lng = event.latLng.lng()
      try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},${event.latLng.lng()}&key=${googleKey()}&language=${this.$i18n.locale}`
        const response = await fetch(url)
        const data = await response.json()
        if (data.status === 'OK') {
          this.center.lat = event.latLng.lat()
          this.center.lng = event.latLng.lng()
          this.form.lat = event.latLng.lat()
          this.form.lng = event.latLng.lng()
        } else {
          console.error(`Geocoding failed with status ${data.status}`)
        }
      } catch (error) {
        console.error(error)
      }
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            district: data.districts.map(item => item.id),
            name: data.name,
            description: data.description,
            facility_type_id: data.facility_type_id,
            lat: data.lat,
            lng: data.lng,
          }
          this.center = {
            lat: data.lat,
            lng: data.lng,
          }
          this.icon = data.icon
        })
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {
        if (!($form.icon instanceof File)) {
          delete $form.icon
        }
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },
  },

}
</script>
